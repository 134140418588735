import App from "./App.vue";
import { createApp, h, provide } from "vue";

import { apolloClient, apolloProvider } from "./plugins/apollo";

import vuetify from "./plugins/vuetify";

import router from "./plugins/router";

import { i18n } from "./plugins/i18n";

import vueDayJS from "@/bytenest-cli/plugins/vue-dayjs";

// @ts-expect-error import
import VueApolloComponents from "@vue/apollo-components";
// @ts-expect-error import
import { loadFonts } from "./plugins/webfontloader";

import { DefaultApolloClient } from "@vue/apollo-composable";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

loadFonts();

import ManiagoEnrollmentForm from "./components/forms/custom/enrollment/ManiagoEnrollmentForm.vue";
import PolinoteEnrollmentForm from "./components/forms/custom/enrollment/PolinoteEnrollmentForm.vue";
import SanVitoEnrollmentForm from "./components/forms/custom/enrollment/SanVitoEnrollmentForm.vue";

const clientName = import.meta.env.VITE_CLIENT_NAME.toLowerCase();

let EnrollmentForm;

switch (clientName) {
  case "maniago":
    EnrollmentForm = ManiagoEnrollmentForm;
    break;
  case "polinote":
    EnrollmentForm = PolinoteEnrollmentForm;
    break;
  case "san vito":
    EnrollmentForm = SanVitoEnrollmentForm;
    break;
  default:
    EnrollmentForm = PolinoteEnrollmentForm;
}

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App),
});

app
  .use(vuetify)
  .use(router)
  .use(i18n)
  .use(apolloProvider)
  .use(VueApolloComponents)
  .use(vueDayJS)
  .component("EnrollmentForm", EnrollmentForm)
  .component("Datepicker", Datepicker)
  .mount("#app");
