<template>
  <base-form
    :model="modelName"
    :fields="fields"
    :loading="loading"
    :defaultValue="baseItem"
    :currentValue="model"
    :saving="saving"
    :duplicating="duplicating"
    :graphQLErrors="graphQLErrors"
    ref="form"
    v-model="newValue"
    class="overflow-hidden"
    @save="onSave"
    @cancel="$emit('cancel')"
  >
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </base-form>
</template>

<script setup lang="ts" generic="Q extends BaseModel, C extends BaseModel, U extends BaseModel">
import { Ref, ref, computed } from "vue";
import { BaseFormInterface, BaseModel, Field } from "@/bytenest-cli/definitions";
import BaseForm from "@/bytenest-cli/form/BaseForm.vue";
import { useFormMutations } from "@/bytenest-cli/composable/formMutations";
import { useQuery } from "@vue/apollo-composable";
import ucFirst from "@/bytenest-cli/utils/ucFirst";
import type { TypedDocumentNode as DocumentNode } from "@apollo/client";
import { PropType } from "vue";
import { Exact } from "../../gql/graphql";
import { GraphQLError } from "graphql";

const props = defineProps({
  modelName: { type: String, required: true },
  modelId: { type: String },
  fields: { type: Array<Field>, required: true },
  query: {
    type: Object as PropType<
      DocumentNode<
        Q,
        Exact<{
          id: string;
        }>
      >
    >,
  },
  createMutation: { type: Object as PropType<DocumentNode<C, Exact<{ input: any }>>>, required: true },
  updateMutation: { type: Object as PropType<DocumentNode<U, Exact<{ id: string; input: any }>>> },
  baseItem: {
    type: Object,
    default: () => ({}),
  },
  duplicating: Boolean,
  preprocessor: { default: dummyTransformer, type: Function as PropType<(input: BaseModel) => BaseModel> },
  preparer: { default: dummyTransformer, type: Function as PropType<(input: BaseModel) => BaseModel> },
});

const newValue: BaseModel = ref({ password: "" });
const graphQLErrors = ref<GraphQLError[]>([]);

const form: Ref<BaseFormInterface | null> = ref(null);

const { updateModel, createModel, saving } = useFormMutations({
  model: ucFirst(props.modelName),
  create_mutation: props.createMutation,
  update_mutation: props.updateMutation,
  preprocessor: props.preprocessor,
});

const emit = defineEmits<{
  created: [payload: C];
  updated: [payload: U];
  cancel: [];
}>();

async function update() {
  if (!props.modelId) return;

  const payload = await updateModel(props.modelId, newValue.value);
  if (payload) emit("updated", payload);
  form.value?.resetInfo();
}

async function create() {
  const payload = await createModel(newValue.value);
  if (payload) emit("created", payload);
  form.value?.resetInfo();
}

const { result, loading } =
  props.modelId && props.query
    ? useQuery(props.query, { id: props.modelId })
    : { result: ref(null), loading: ref(false) };

const model = computed(() => (result.value ? props.preparer({ ...result.value[props.modelName] }) : {}));

function onSave() {
  if (props.modelId && !props.duplicating) update();
  else create();
}
</script>

<script lang="ts">
function dummyTransformer(model: BaseModel) {
  return model;
}
</script>
