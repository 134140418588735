import { BaseModel, Field, getField } from "@/bytenest-cli/definitions";
import { graphql } from "@/gql";
import { required } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";

const StudentsWithLocation = graphql(`
  query StudentsWithLocation {
    me {
      availableStudents {
        id
        full_name
        location {
          id
        }
      }
    }
  }
`);

const CourseAvailableTeachers = graphql(`
  query CourseAvailableTeachers($course_id: ID!) {
    course(id: $course_id) {
      id
      availableTeachers {
        id
        user {
          id
          full_name
        }
      }
    }
  }
`);

const LocationCourses = graphql(`
  query LocationCourses($location_id: ID!) {
    location(id: $location_id) {
      courses {
        id
        name
        payment_info_required
      }
    }
  }
`);

const CourseFrequencies = graphql(`
  query CourseFrequencies($course_id: ID!) {
    courseFrequencies(course_id: $course_id) {
      id
      description
      start_timestamp
    }
  }
`);

interface IProps {
  enrollment_id?: string;
  baseItem: BaseModel;
}

export const useEnrollmentFields = (props: IProps) => {
  const { t } = useI18n();

  const Students = getField({
    name: "student",
    type: "query",
    path: "me.availableStudents",
    query: StudentsWithLocation,
    label: t("student.name"),
    text: "full_name",
    validation: {
      required,
    },
    bind: {
      readonly: props.enrollment_id != null || "student" in props.baseItem,
    },
  });

  const Course = getField({
    name: "course",
    type: "query",
    path: "location.courses",
    query: LocationCourses,
    variables: (model: BaseModel) => ({ location_id: model.student?.location.id }),
    skip: (model: BaseModel) => !model.student,
    label: t("course.name"),
    validation: {
      required,
    },
  });

  const CourseFrequency = getField({
    name: "courseFrequency",
    type: "query",
    path: "courseFrequencies",
    text: "description",
    validation: {
      required,
    },
    query: CourseFrequencies,
    variables: (model) => ({ course_id: model.course?.id }),
    skip: (model) => !model.course,
    label: t("courseFrequency.name"),
  });

  const Teacher = getField({
    name: "teacher",
    path: "course.availableTeachers",
    type: "query",
    query: CourseAvailableTeachers,
    variables: (model) => ({ course_id: model.course?.id }),
    skip: (model) => !model.course,
    label: t("teacher.name"),
    text: "user.full_name",
    validation: {
      required,
    },
  });

  const Instrument: Field = {
    name: "instrument",
  };

  const StartTimestamp: Field = {
    type: "datetime",
    name: "start_timestamp",
    isVisible: ({ courseFrequency }) => courseFrequency && !courseFrequency.start_timestamp,
    validation: {
      required,
    },
  };

  return {
    Students,
    Course,
    CourseFrequency,
    Teacher,
    Instrument,
    StartTimestamp,
  };
};
