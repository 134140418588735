<template>
  <v-card class="my-1" elevation="0" rounded v-for="(item, i) in values" :key="i">
    <v-card-title class="d-flex">
      <span class="text-capitalize">{{ field.label }} - {{ i }}</span>
      <v-spacer></v-spacer>
      <v-btn
        v-if="i > 0"
        icon="mdi-close"
        variant="outlined"
        size="small"
        color="danger"
        @click="removeElement(i)"
      ></v-btn>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col
          v-for="sub_field in field.fields"
          :key="sub_field.name"
          cols="12"
          :md="sub_field.cols || '6'"
          style="max-width: 100%"
          :class="[{ 'order-last': sub_field.last }, 'flex-grow-1', 'flex-shrink-0', 'px-4']"
        >
          <base-input
            v-model="item[sub_field.name]"
            :field="sub_field"
            :label="sub_field.label"
            :apolloParams="{
              variables: sub_field.variables ? sub_field.variables(modelEdit, i) : {},
              skip: sub_field.skip ? sub_field.skip(modelEdit, i) : false,
            }"
            @validate="$emit('validate')"
            @update:modelValue="$emit('update:modelValue', values)"
          >
          </base-input>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-row no-gutters class="py-2 justify-center">
    <v-btn color="primary" @click="addElement">Aggiungi corso</v-btn>
  </v-row>
</template>

<script setup lang="ts">
import { Ref, ref } from "vue";
import BaseInput from "./BaseInput.vue";

defineProps({
  field: { type: Object, required: true },
  errors: Array,
  modelEdit: Object,
  modelValue: {
    required: true,
  },
});

//Emits
const emit = defineEmits(["update:modelValue", "validate"]);

emit("update:modelValue", [{}]);
const values: Ref<object[]> = ref([{}]);

function addElement() {
  values.value.push({});
  emit("update:modelValue", values.value);
}

function removeElement(i) {
  values.value.splice(i, 1);
  emit("update:modelValue", values.value);
}
</script>
