import { ApolloClient, ApolloLink, InMemoryCache, concat, from } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { createApolloProvider } from "@vue/apollo-option";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import possibleTypes from "./possibleTypes.json";

// Name of the localStorage item
const AUTH_TOKEN = import.meta.env.VITE_AUTH_TOKEN;

// HTTP connection to the API
const httpLink = createUploadLink({
  // You should use an absolute URL here
  uri: import.meta.env.VITE_API_URI,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem(AUTH_TOKEN);
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

// Cache implementation
const cache = new InMemoryCache({ possibleTypes });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        "%cError[GraphQL]",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        `\n${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError)
    console.log("%cError[Network]", "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;", `\n${networkError}`);
});

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: concat(authMiddleware, from([errorLink, httpLink])),
  cache,
});

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,

  errorHandler(error) {
    // eslint-disable-next-line no-console
    console.log("%cError", "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;", error.message);
  },
});

// Manually call this when user log in
export async function onLogin(apolloClient: ApolloClient<any>, token: string) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  try {
    await apolloClient.resetStore();
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient: ApolloClient<any>) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  try {
    await apolloClient.resetStore();
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
